import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import '../Styles/Form.css';
import userIcon from '../../assets/name-icon.svg';
import locationIcon from '../../assets/location-icon.svg';
import countyIcon from '../../assets/county-icon.svg';
import phoneIcon from '../../assets/phone-icon.svg';
import { createRecord } from '../../Utils/db';
import { useNavigate } from 'react-router-dom';

function CheckoutForm({ item }) {
    const [formData, setFormData] = useState({
        name: '',
        county: '',
        location: '',
        phone: '',
        amount: '',
        quantity: 1,
        note: '',
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) return; // Prevent multiple submissions

        if (!formData.name || !formData.county || !formData.location || !formData.phone) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            setLoading(true);
            const postData = {
                ...formData,
                item: item.uuid,
                price: item.price,
                amount: calculateTotal(),
            };

            console.log("Order data: ", postData);

            const response = await createRecord('orders', postData);
            console.log("Order response: ", response);

            if (response) {
                setFormData({
                    name: '',
                    county: '',
                    location: '',
                    phone: '',
                    amount: '',
                    quantity: 1,
                    note: '',
                });
                navigate('/success', { state: { item: postData, itemName: item.title, price: item.price } });
            } else {
                toast.error('Failed to place order. Please try again.');
            }
        } catch (error) {
            console.error('Error placing order:', error);
            toast.error('An error occurred while placing your order. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Handle input field change
    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }, []);

    // Handle quantity change
    const handleQuantityChange = useCallback((increment) => {
        setFormData((prevValues) => ({
            ...prevValues,
            quantity: Math.max(1, prevValues.quantity + increment),
        }));
    }, []);

    // Calculate total amount
    const calculateTotal = () => {
        const price = Number(item.price);
        const quantity = formData.quantity;
        let total = 0;

        if (item.discount !== 0) {
            if (quantity === 1) {
                total = price;
            } else {
                const priceForOne = price ;
                total = priceForOne + (quantity - 1) * (0.8 * price);
            }
        } else {
            total = price * quantity;
        }

        const roundedTotal = Math.ceil(total / 10) * 10;

        return roundedTotal.toFixed(2);
    };

    // Auto-submit form data when the user tries to exit or perform specific actions
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (formData.phone && formData.phone.length >= 10 && !loading) {
                const postData = {
                    ...formData,
                    item: item.uuid,
                    price: item.price,
                    amount: calculateTotal(),
                };

                // Auto-fill empty fields with "auto filled <field name>"
                for (const key in postData) {
                    if (postData[key] === '' && key !== 'phone') {
                        postData[key] = `auto filled ${key}`;
                    }
                }

                // Send data to backend if phone is filled
                createRecord('orders', postData).then((response) => {
                    if (!response) {
                        toast.error('Failed to auto-save order data.');
                    }
                });

                // Custom message (optional)
                const message = "You have unsaved changes. Are you sure you want to leave?";
                e.returnValue = message; // Standard for most browsers
                return message; // For some browsers
            }
        };

        const handlePopState = () => {
            if (formData.phone && formData.phone.length >= 10 && !loading) {
                // Handling back button press event
                autoUpload('Pressed back');
            }
        };

        const handleRelatedItemClick = (e) => {
            if (formData.phone && formData.phone.length >= 10 && !loading) {
                // Handling clicking on a related item
                autoUpload('Clicked related item');
            }
        };

        const handleTabRefresh = () => {
            if (formData.phone && formData.phone.length >= 10 && !loading) {
                // Handling tab refresh event
                autoUpload('Tab refresh');
            }
        };

        const autoUpload = (eventType) => {
            const postData = {
                ...formData,
                item: item.uuid,
                price: item.price,
                amount: calculateTotal(),
            };

            // Auto-fill empty fields with "auto filled <field name>"
            for (const key in postData) {
                if (postData[key] === '' && key !== 'phone') {
                    postData[key] = `auto filled ${key}`;
                }
            }

            console.log(`Auto uploading due to event: ${eventType}`);

            // Send data to backend only if phone number length is 10 or more
            if (postData.phone.length >= 10) {
                createRecord('orders', postData).then((response) => {
                    if (!response) {
                        toast.error('Failed to auto-save order data.');
                    }
                });
            } else {
                console.log('Phone number length is less than 10. Skipping auto-upload.');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);
        window.addEventListener('click', handleRelatedItemClick);
        window.addEventListener('beforeunload', handleTabRefresh);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('click', handleRelatedItemClick);
            window.removeEventListener('beforeunload', handleTabRefresh);
        };
    }, [formData, loading, item]);

    return (
        <form onSubmit={handleSubmit}>
            <h3>Fill the Form Below to place your order</h3>
            <div className='form-inputs'>
                <div className="input-group">
                    <img src={userIcon} alt="User" className="input-icon" />
                    <input
                        name="name"
                        type='text'
                        placeholder='Your Name'
                        onChange={handleInputChange}
                        value={formData.name}
                    />
                </div>
                <div className="input-group">
                    <img src={phoneIcon} alt="Phone" className="input-icon" />
                    <input
                        name="phone"
                        type='text'
                        placeholder='Your Phone Number, e.g. 07xxxxxxxx'
                        onChange={handleInputChange}
                        value={formData.phone}
                    />
                </div>
                <div className="input-group">
                    <img src={countyIcon} alt="County" className="input-icon" />
                    <input
                        name="county"
                        type='text'
                        placeholder='County of Residence'
                        onChange={handleInputChange}
                        value={formData.county}
                    />
                </div>
                <div className="input-group">
                    <img src={locationIcon} alt="Location" className="input-icon" />
                    <input
                        name="location"
                        type='text'
                        placeholder='Enter Building/Street/Landmark'
                        onChange={handleInputChange}
                        value={formData.location}
                    />
                </div>
            </div>
            <div className='order-summary'>
                <h2>Order Summary</h2>
                <div className='order-item'>
                    <p>SubTotal</p>
                    <p>{item.price}</p>
                </div>
                <div className='order-item'>
                    <label htmlFor='quantity'>Quantity</label>
                    <div className='quantity-buttons'>
                        <button
                            type='button'
                            className='quantity-button'
                            onClick={() => handleQuantityChange(-1)}
                        >
                            -
                        </button>
                        <input
                            id='quantity'
                            name='quantity'
                            type='number'
                            min='1'
                            value={formData.quantity}
                            onChange={(e) => handleQuantityChange(Number(e.target.value) - formData.quantity)}
                        />
                        <button
                            type='button'
                            className='quantity-button'
                            onClick={() => handleQuantityChange(1)}
                        >
                            +
                        </button>
                    </div>
                </div>
                <div className='order-item total'>
                    <p>Total</p>
                    <p><strong>{calculateTotal()}</strong></p>
                </div>
            </div>
            <div className='note-field'>
                <label htmlFor='note'>Note</label>
                <textarea
                    id='note'
                    name='note'
                    placeholder='Any special instructions or notes...'
                    onChange={handleInputChange}
                    value={formData.note}
                ></textarea>
            </div>
            <button type='submit' disabled={loading}>
                {loading ? 'Processing Order...' : 'Place Your Order'}
            </button>
        </form>
    );
}

export default CheckoutForm;
