export function findRelatedProducts(selectedProduct, ProductsData = null) {
  if (!ProductsData || !selectedProduct || !selectedProduct.tags || !Array.isArray(selectedProduct.tags)) {
    return ProductsData ? ProductsData.slice(-5) : []; // Return last 5 if no valid tags
  }

  const relatedProducts = [];

  for (let product of ProductsData) {
    if (!product.tags || !Array.isArray(product.tags)) continue; // Skip if tags are missing

    const similarityScore = calculateTagSimilarity(selectedProduct.tags, product.tags);
    if (similarityScore > 0) {
      relatedProducts.push({ product, score: similarityScore });
    }
  }

  // Sort by similarity score in descending order and return top 5
  return relatedProducts
    .sort((a, b) => b.score - a.score) // Sort by highest similarity
    .slice(0, 5) // Select top 5 products
    .map(entry => entry.product); // Return only the product objects
}

// Function to calculate Jaccard similarity based on tags
export function calculateTagSimilarity(tagsA, tagsB) {
  const setA = new Set(tagsA.map(tag => tag.toLowerCase())); // Normalize to lowercase
  const setB = new Set(tagsB.map(tag => tag.toLowerCase()));

  const intersection = [...setA].filter(tag => setB.has(tag)).length;
  const union = new Set([...setA, ...setB]).size;

  return union === 0 ? 0 : intersection / union; // Jaccard similarity formula
}
